<script setup lang="ts">
import type { FormTheme } from "@/types";

const props = defineProps<{
  pageRef: HTMLDivElement | null;
  theme?: FormTheme;
  static?: boolean;
}>();

// Fetch route and form store information
const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const form = computed(() => store.forms[id.value]);
const theme: ComputedRef<FormTheme> = computed(
  () => props.theme || form.value?.theme
);

// Reactive variables for dragging
const imageLoaded = ref(false);
const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const maxMoveX = ref(0);
const maxMoveY = ref(0);

// Refs for DOM elements
const imageRef = ref<HTMLImageElement | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

// Computed property for image style
const bgImageStyle = computed(() => {
  const coords = {
    x: theme && theme.value.bgImageOffset ? theme.value.bgImageOffset.x : 50,
    y: theme && theme.value.bgImageOffset ? theme.value.bgImageOffset.y : 50,
  };
  return {
    objectPosition: `${coords.x}% ${coords.y}%`,
  };
});

// Compute the drag factor based on visible area
const dragFactor = ref(1);
const updateDragFactor = () => {
  if (props.static) return;
  const pageRect = props.pageRef?.getBoundingClientRect();
  const containerRect = containerRef.value?.getBoundingClientRect();
  if (!pageRect || !containerRect) {
    dragFactor.value = 1;
    return;
  }

  // determine which is larger, width or height of containerRect
  if (containerRect.height > containerRect.width) {
    dragFactor.value = (containerRect.width / pageRect.width) * 1;
  } else {
    dragFactor.value = (containerRect.height / pageRect.height) * 2;
  }
};

// Center the image on load
const handleImageLoaded = () => {
  imageLoaded.value = true;
  updateDraggableArea();
};

// Center the image in its container
const centerImage = () => {
  if (!theme.value || !theme.value.bgImageOffset) return;
  theme.value.bgImageOffset.x = 50;
  theme.value.bgImageOffset.y = 50;
};

// Update draggable area
const updateDraggableArea = () => {
  if (props.static) return;
  if (!imageRef.value || !containerRef.value) return;

  const imgRect = imageRef.value.getBoundingClientRect();
  const containerRect = containerRef.value.getBoundingClientRect();

  maxMoveX.value = Math.max(
    0,
    ((imgRect.width - containerRect.width) / imgRect.width) * 100
  );
  maxMoveY.value = Math.max(
    0,
    ((imgRect.height - containerRect.height) / imgRect.height) * 100
  );

  setTimeout(() => {
    updateDragFactor();
  }, 100);
};

// Start dragging the image
const startDrag = (event: MouseEvent) => {
  if (props.static) return;
  event.preventDefault();
  isDragging.value = true;
  startX.value = event.clientX;
  startY.value = event.clientY;
};

// Handle the dragging motion
const doDrag = (event: MouseEvent) => {
  if (props.static) return;
  if (!isDragging.value || !containerRef.value) return;

  const deltaX = startX.value - event.clientX;
  const deltaY = startY.value - event.clientY;

  const containerRect = containerRef.value.getBoundingClientRect();

  // Apply drag factor to adjust speed
  const adjustedDeltaX = deltaX * dragFactor.value;
  const adjustedDeltaY = deltaY * dragFactor.value;

  // Calculate movement percentage relative to the container size
  const deltaXPercent = (adjustedDeltaX / containerRect.width) * 100;
  const deltaYPercent = (adjustedDeltaY / containerRect.height) * 100;

  // Update image position, bounded to allow full reveal of the image
  theme.value.bgImageOffset.x = Math.max(
    0,
    Math.min(100, theme.value.bgImageOffset.x + deltaXPercent)
  );
  theme.value.bgImageOffset.y = Math.max(
    0,
    Math.min(100, theme.value.bgImageOffset.y + deltaYPercent)
  );

  // Update start positions for the next movement calculation
  startX.value = event.clientX;
  startY.value = event.clientY;
};

// Stop dragging the image
const stopDrag = () => {
  if (props.static) return;
  isDragging.value = false;
};

// Watch for changes in theme to update draggable area
watch(
  () => theme.value?.bgImageUrl,
  () => {
    updateDraggableArea();
    centerImage();
  }
);
watch(
  () => theme.value?.layout,
  () => {
    updateDraggableArea();
    centerImage();
  }
);
watch(
  () => theme.value?.formBgTransparency,
  (newVal, prevVal) => {
    if (newVal === 0 || prevVal === 0) {
      updateDraggableArea();
    }
  }
);

// Set up and clean up event listeners
onMounted(() => {
  if (props.static) return;
  window.addEventListener("mousemove", doDrag);
  window.addEventListener("mouseup", stopDrag);
  window.addEventListener("resize", updateDragFactor);
  updateDraggableArea();
});

onUnmounted(() => {
  if (props.static) return;
  window.removeEventListener("mousemove", doDrag);
  window.removeEventListener("mouseup", stopDrag);
  window.removeEventListener("resize", updateDragFactor);
});
</script>

<template>
  <div
    v-if="theme"
    class="background-image pointer-events-none !absolute inset-0"
    ref="containerRef"
  >
    <div
      :class="`pointer-events-none !absolute inset-0 fk-filter-${theme.bgFilter} z-20`"
      :style="`opacity: ${theme.bgFilterIntensity}%;`"
    >
      <img
        ref="imageRef"
        :key="theme.bgImageUrl"
        class="pointer-events-auto absolute inset-0 w-full h-full object-cover"
        :class="{ 'cursor-grabbing': isDragging }"
        :style="bgImageStyle"
        loading="lazy"
        :src="theme.bgImageUrl"
        alt=""
        role="presentation"
        @load="handleImageLoaded"
        @mousedown="startDrag"
        @dragstart.prevent
      />
    </div>
    <img
      :key="theme.bgImageUrl"
      class="absolute max-w-none w-full h-full object-cover"
      :style="bgImageStyle"
      loading="lazy"
      :src="theme.bgImageUrl"
      alt=""
      role="presentation"
    />
    <BlurHash
      v-if="!imageLoaded"
      class="!absolute z-10 top-0 left-0 w-full h-full object-cover object-center"
      :hash="theme.bgImageBlurHash"
    />
  </div>
</template>

<style scoped>
img {
  cursor: move;
  cursor: grab;
  user-select: none;
  -webkit-user-drag: none;
}
.cursor-grabbing {
  cursor: grabbing;
}
</style>
