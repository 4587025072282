<script setup lang="ts">
import type { FormTheme, Page } from "@/types";
import type { FormKitSchemaNode } from "@formkit/core";
import { cloneAny, eq } from "@formkit/utils";

import { layoutClasses } from "~/assets/formPageLayoutClasses";
import { insert } from "~/utils/drag-and-drop";
import { dragAndDrop } from "~/utils/drag-and-drop/vue";
import { insertPointClasses } from "~/utils";

const props = defineProps<{
  theme?: FormTheme;
  static?: boolean;
  blurUnderlay?: boolean;
  page?: Page;
  pageIndex?: number;
}>();

const route = useRoute();
const store = useFormStore();
const systemStore = useSystemStore();
const { generatingForm } = storeToRefs(store);
const { activeElementControls } = storeToRefs(systemStore);
const pageContainer = ref<HTMLDivElement | null>(null);
const pageScroller = ref<HTMLDivElement | null>(null);
const pageContent = ref<HTMLDivElement | null>(null);
const pageHasScrollbars = ref(false);
const scrollOffset = ref(0);
const dndParent = ref();

//const loading = store.loading;

const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);

const form = computed(() => store.forms[id.value!]);

const theme: ComputedRef<FormTheme> = computed(
  () => props.theme || form.value?.theme
);
const formLoading = computed(() => form.value?.loading);
const layout = computed(() => {
  return theme.value?.layout || "minimal";
});

// TODO: Is this artifact?
const computedScrollOffset = computed(() => {
  return layout.value === "top-image" && !theme.value?.bgImageCover
    ? scrollOffset.value
    : 0;
});
const bgImageBrightness = computed(() => {
  if (typeof theme.value?.bgImageBrightness === "number") {
    const brightness = theme.value.bgImageBrightness;

    const clampedBrightness = Math.max(20, Math.min(180, brightness));

    const position = (clampedBrightness - 20) / 160;

    let r, g, b, a;

    if (position <= 0.5) {
      r = g = b = 0;
      a = 0.75 * (1 - position * 2);
    } else {
      r = g = b = 255;
      a = 0.75 * ((position - 0.5) * 2);
    }
    return `rgba(${Math.round(r)},${Math.round(g)},${Math.round(b)},${a.toFixed(
      2
    )})`;
  }

  return `rgba(255,255,255,0)`;
});
const bgImageBrightnessPercent = computed(() => {
  return Math.min(
    Math.max(Math.round(theme.value?.bgImageBrightness || 100), 50),
    125
  );
});
const bgImageTransparency = computed(() => {
  if (typeof theme.value?.bgImageTransparency === "number") {
    return 1 - theme.value.bgImageTransparency / 100;
  }
  return 1;
});
const bgImageCover = computed(() => {
  if (typeof theme.value?.bgImageCover === "boolean") {
    return theme.value.bgImageCover;
  }
  return false;
});

const formBgTransparency = computed(() => {
  if (typeof theme.value?.formBgTransparency === "number") {
    return 1 - theme.value.formBgTransparency / 300;
  }
  return 1;
});
const formBgBlur = computed(() => {
  if (typeof theme.value?.formBgBlur === "boolean") {
    return theme.value.formBgBlur ? 10 : 0;
  }
  return 10;
});
const formMaxWidth = computed(() => {
  if (typeof theme.value?.formMaxWidth === "number") {
    return `${theme.value.formMaxWidth}px`;
  }
  return "100%";
});

const updateScrollOffset = () => {
  if (pageScroller.value) {
    scrollOffset.value = pageScroller.value.scrollTop;
  }
};

const checkHasScrollbars = () => {
  if (pageContent.value && pageScroller.value) {
    const contentHeight = pageContent.value.offsetHeight;
    const scrollerHeight = pageScroller.value.offsetHeight;

    pageHasScrollbars.value = contentHeight > scrollerHeight;
  }
};

onMounted(() => {
  checkHasScrollbars();
  window.addEventListener("resize", checkHasScrollbars);

  if (pageScroller.value) {
    pageScroller.value?.addEventListener("scroll", updateScrollOffset);
  }

  if (props.page) {
    if (formLoading.value === false && generatingForm.value === false) {
      initDnd(dndParent.value);

      return;
    }

    watch(
      [formLoading, generatingForm],
      ([newFormValue, newGeneratingFormValue]) => {
        if (newFormValue || newGeneratingFormValue) return;

        initDnd(dndParent.value);
      }
    );
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", checkHasScrollbars);

  if (pageScroller.value) {
    pageScroller.value?.removeEventListener("scroll", updateScrollOffset);
  }
});

function initDnd(el: HTMLElement) {
  if (!props.page || props.pageIndex === undefined) return;

  const schema = computed({
    get: () => {
      return form.value.pages[props.pageIndex as number]?.schema || [];
    },
    set: (value) => {
      if (props.pageIndex === undefined) return;
      // Update the schema in the store
      const updatedSchemaPages = form.value.schemaPages;

      updatedSchemaPages[props.pageIndex].schema = value;

      form.value.schemaPages = updatedSchemaPages;
    },
  });

  const dragHandleEl = document.getElementById("drag-handle");

  if (!dragHandleEl) return;

  dragAndDrop({
    parent: el,
    values: schema,
    group: "formkit-builder",
    treeGroup: "formkit-builder",
    dragHandle: ".drag-handle",
    dropZoneClass: "border-fk-accent bg-fk-accent-lightest",
    synthDropZoneClass: "border-fk-accent bg-fk-accent-lightest",
    activeDescendantClass: "border-fk-accent",
    externalDragHandle: {
      el: dragHandleEl,
      callback: () => {
        if (!activeElementControls.value) throw new Error("No active element");

        return activeElementControls.value.parentNode as HTMLElement;
      },
    },
    synthDragImage: (node, parent, e, draggedNodes) => {
      const wrapper = document.createElement("div");

      const clonedNode = node.el.cloneNode(true) as HTMLElement;

      const dragHandleEl = document.getElementById("drag-handle");

      const dragHandleParent = dragHandleEl?.parentNode;

      if (
        !(dragHandleParent instanceof HTMLElement) ||
        !(dragHandleEl instanceof HTMLElement) ||
        !(dragHandleEl.children[0] instanceof HTMLElement)
      )
        throw new Error("Drag handle parent not found");

      const dragHandleParentRect = dragHandleParent.getBoundingClientRect();

      const startLeft = e.clientX - dragHandleParentRect.left;

      Object.assign(clonedNode.style, {
        margin: 0,
        marginLeft: "-15px",
      });

      if (!(clonedNode.children[0] instanceof HTMLElement))
        throw new Error("First child is not an HTMLElement");
      clonedNode.children[0].dataset.showingEditor = "false";

      if (!dragHandleEl || !(dragHandleEl.parentNode instanceof HTMLElement))
        throw new Error("Drag handle not found");

      const dragHandleClone = dragHandleEl?.parentNode.cloneNode(
        true
      ) as HTMLElement;

      Object.assign(dragHandleClone.style, {
        left: "0px",
        position: "relative",
      });

      clonedNode.style.pointerEvents = dragHandleClone.style.pointerEvents =
        "none";

      Object.assign(clonedNode.style, {
        position: "relative",
      });

      wrapper.append(dragHandleClone, clonedNode);

      Object.assign(wrapper.style, {
        width: dragHandleEl.offsetWidth + node.el.offsetWidth + "px",
        left: "0px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      });

      return {
        dragImage: wrapper,
        offsetX: startLeft,
      };
    },
    nativeDrag: false,
    draggable: (el) => {
      return true;
    },
    plugins: [
      insert({
        insertPoint: () => {
          const div = document.createElement("div");

          for (const cls of insertPointClasses) div.classList.add(cls);

          return div;
        },
      }),
    ],
  });

  function getNestedRepeaters(root: HTMLElement): HTMLElement[] {
    const repeaters: HTMLElement[] = [];

    function traverse(element: HTMLElement) {
      // Check if the current element is a repeater
      if (element.matches(`[data-type="repeater"] .formkit-items li > div`)) {
        repeaters.push(element);
      }

      // Recursively traverse child elements
      element.childNodes.forEach((child) => {
        if (child instanceof HTMLElement) {
          traverse(child);
        }
      });
    }

    traverse(root);
    return repeaters;
  }

  function handleRepeaters(sourceEl: HTMLElement, schema: ComputedRef<any>) {
    const allRepeaterSchema = computed(() => {
      const findRepeaters = (nodes: any[], path: number[] = []): any[] => {
        let repeaters: any[] = [];

        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];

          if ("type" in node && node.type === "repeater") {
            repeaters.push({ node, path: [...path, i] });
          }

          if (node.children?.length) {
            repeaters = repeaters.concat(
              findRepeaters(node.children, [...path, i])
            );
          }
        }

        return repeaters;
      };

      return findRepeaters(schema.value || []);
    });

    const initializeRepeaters = (allRepeaterEls: HTMLElement[]) => {
      for (let x = 0; x < allRepeaterEls.length; x++) {
        const repeaterEl = allRepeaterEls[x];

        if (!allRepeaterSchema.value[x]) continue;

        let { path } = allRepeaterSchema.value[x];

        const repeaterChildren = computed({
          get: () => {
            return allRepeaterSchema.value[x]?.node.children || [];
          },
          set: (value) => {
            const updatedSchemaPages = form.value.schemaPages;

            // Filter out items and track removed indices to adjust path
            let removedBeforePath = 0;
            const filteredSchemaPages = updatedSchemaPages[0].schema.filter(
              (page, index) => {
                const shouldKeep = !value.some((item) => eq(item, page));
                if (!shouldKeep && index < path[0]) {
                  removedBeforePath++;
                }
                return shouldKeep;
              }
            );
            path[0] -= removedBeforePath; // Decrement path index based on removed items

            updatedSchemaPages[0].schema = filteredSchemaPages;

            const updateNestedChildren = (nodes: any[], path: number[]) => {
              const currentPath = path.shift();
              if (currentPath === undefined) return;

              if (path.length === 0) {
                nodes[currentPath].children = value;
              } else {
                updateNestedChildren(nodes[currentPath].children, path);
              }
            };

            const pageSchema =
              updatedSchemaPages[props.pageIndex as number].schema;

            if (!pageSchema) return;

            updateNestedChildren(pageSchema, [...path]);

            updatedSchemaPages[props.pageIndex as number].schema = [
              ...pageSchema,
            ];

            form.value.schemaPages = updatedSchemaPages;

            // Delay to ensure nested DOM elements are rendered before initializing
            setTimeout(() => {
              handleRepeaters(sourceEl, schema);
            }, 0);
          },
        });

        dragAndDrop({
          parent: repeaterEl,
          values: repeaterChildren,
          group: "formkit-builder",
          treeGroup: "formkit-builder",
          dropZoneClass: "border-fk-accent bg-fk-accent-lightest",
          synthDropZoneClass: "border-fk-accent bg-fk-accent-lightest",
          activeDescendantClass: "border-fk-accent",
          nativeDrag: false,
          draggable: (el) => true,
          plugins: [
            insert({
              insertPoint: () => {
                const div = document.createElement("div");
                for (const cls of insertPointClasses) div.classList.add(cls);
                return div;
              },
            }),
          ],
        });
      }
    };

    // Get initial repeaters and initialize them
    const allRepeaterEls = getNestedRepeaters(sourceEl);
    initializeRepeaters(allRepeaterEls);

    // Observe DOM changes to reinitialize repeaters dynamically
    const mutationObserver = new MutationObserver(() => {
      const updatedRepeaterEls = getNestedRepeaters(sourceEl); // Get updated repeaters
      initializeRepeaters(updatedRepeaterEls);
    });

    mutationObserver.observe(sourceEl, {
      childList: true,
      subtree: true, // Include nested changes
    });
  }

  // TODO: Fix this
  setTimeout(() => {
    handleRepeaters(el, schema);
  }, 1000);

  const mutationObserver = new MutationObserver(() => {
    handleRepeaters(el, schema);
  });

  mutationObserver.observe(el, {
    childList: true,
  });
}
</script>

<template>
  <div
    class="@container overflow-clip z-10 h-full flex-1"
    ref="pageContainer"
    :inert="static"
    :data-bg-shadow="theme?.formBgShadow"
    :data-has-scrollbars="pageHasScrollbars"
    :class="`${layoutClasses.base.outer} ${
      layoutClasses[layout] && layoutClasses[layout].outer
    } ${static ? 'pointer-events-none' : ''}`"
  >
    <div
      v-if="theme && theme.logo"
      :class="`
          ${layoutClasses.base.logo}
          transition-all ease-bounce duration-500 rounded-md overflow-clip
          ${layoutClasses[layout] && layoutClasses[layout].logo}
        `"
    >
      <img
        class="object-contain max-w-[140px] max-h-[70px] w-full"
        :src="theme.logo"
        alt="Logo"
      />
    </div>

    <EditorFormKitCredit
      v-if="theme && theme.formKitBranding"
      :class="`
          hidden @3xl:flex
          ${layoutClasses.base.credit}
          pointer-events-none transition-all ease-bounce duration-500
          ${layoutClasses[layout] && layoutClasses[layout].credit}
        `"
    />

    <div
      ref="pageScroller"
      :class="`page-scroller @5xl:pointer-events-none relative flex will-change-scroll overflow-auto
      w-full h-full z-20 [contain:paint] transform-gpu  @5xl:overscroll-auto`"
    >
      <div
        class="pointer-events-none transition-all duration-500 ease-bounce"
        :class="`${layoutClasses.base.wrapper} ${
          layoutClasses[layout] && layoutClasses[layout].wrapper
        }`"
        :style="
          layout === 'card' ? `max-width: calc(${formMaxWidth} + 40px) ;` : ``
        "
      >
        <div
          class="content pointer-events-none transition-all duration-500 ease-bounce shadow-[0_0_0_0_rgba(0,0,0,0)] will-change-[top,left,width,height]"
          :class="`${layoutClasses.base.content} ${
            layoutClasses[layout] && layoutClasses[layout].content
          }`"
        >
          <div
            v-if="blurUnderlay && theme.formBgBlur"
            :class="`
              blur-underlay
              ${layoutClasses.base.blurUnderlay}
              ${layoutClasses[layout] && layoutClasses[layout].blurUnderlay}
            `"
            :style="`
              -webkit-backdrop-filter: blur(${formBgBlur}px);
              backdrop-filter: blur(${formBgBlur}px);
            `"
          />
          <div
            ref="pageContent"
            class="pointer-events-auto transition-all duration-500 ease-bounce shadow-[0_0_0_0_rgba(0,0,0,0)] will-change-[top,left,width,height]"
            :class="`
                ${layoutClasses.base.contentInner}
                ${layoutClasses[layout] && layoutClasses[layout].contentInner}
                flex-wrap flex-col !pl-4 @2xl:!pl-6 @5xl:!pl-7 !pr-5 @2xl:!pr-8 @5xl:!pr-12
              `"
            :style="
              blurUnderlay
                ? `background-color: rgba(255, 255, 255, ${formBgTransparency})`
                : `
                background-color: rgba(255, 255, 255, ${formBgTransparency});
                -webkit-backdrop-filter: blur(${formBgBlur}px);
                backdrop-filter: blur(${formBgBlur}px);
              `
            "
          >
            <div
              :class="`transform-gpu pl-5 md:pl-8 ${layoutClasses.base.form}`"
              :style="`max-width: ${formMaxWidth}; font-family: var(--fk-font-family);`"
              :id="page?.id"
              ref="dndParent"
            >
              <slot />
            </div>

            <div class="w-full flex justify-end @3xl:hidden">
              <EditorFormKitCredit
                v-if="theme && theme.formKitBranding"
                :class="`
                    mx-auto mt-10 pointer-events-none transition-all ease-bounce duration-500
                  `"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="theme && theme.bgImageEnabled"
      class="transition-[top,left,width,height] duration-500 ease-bounce will-change-[top,left,width,height] transform-gpu max-w-full max-h-full"
      :class="`
          ${layoutClasses.base.image}
          ${
            formBgTransparency < 1 && (bgImageCover || layout === 'minimal')
              ? layoutClasses['card'].image
              : layoutClasses[layout] && layoutClasses[layout].image
          }
        `"
    >
      <div
        :class="`pointer-events-none absolute inset-0 z-10`"
        :style="`background: ${bgImageBrightness};`"
      />
      <div
        class="pointer-events-none !absolute inset-0"
        :style="`filter: brightness(${bgImageBrightnessPercent}%);`"
      >
        <EditorPageBackgroundImage
          :static="static"
          :theme="theme"
          :pageRef="pageContainer"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.ease-bounce {
  transition-timing-function: cubic-bezier(0.1, 1.2, 0.05, 1) !important;
}
</style>
