<script setup lang="ts">
import { decode } from "blurhash";

interface Props {
  hash: string;
}

const props = defineProps<Props>();
const canvasRef = ref<HTMLCanvasElement | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

const formStore = useFormStore();
const { blurHashes } = storeToRefs(formStore);

const renderBlurHash = () => {
  const canvas = canvasRef.value;
  const container = containerRef.value;

  if (!canvas || !props.hash || !container) {
    return;
  }

  const { width, height } = container.getBoundingClientRect();
  const roundedWidth = Math.round(width) || 300;
  const roundedHeight = Math.round(height) || 300;
  canvas.width = roundedWidth;
  canvas.height = roundedHeight;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  const cacheKey = `${props.hash}-${roundedWidth}x${roundedHeight}`;
  let pixels = blurHashes.value[cacheKey];

  if (!pixels) {
    pixels = decode(props.hash, roundedWidth, roundedHeight);
    blurHashes.value[cacheKey] = pixels;
  }

  const imageData = ctx.createImageData(roundedWidth, roundedHeight);
  imageData.data.set(pixels);
  ctx.putImageData(imageData, 0, 0);
};

onMounted(() => {
  renderBlurHash();
});

watch(
  () => props.hash,
  () => {
    renderBlurHash();
  }
);
</script>

<template>
  <div ref="containerRef">
    <canvas
      ref="canvasRef"
      class="absolute z-10 top-0 left-0 w-full h-full object-cover object-center"
    />
  </div>
</template>
